export const filterKey = {
  HitLevel: 'hitLevel',
  StartupFrameMin: 'startupFrameMin',
  StartupFrameMax: 'startupFrameMax',
  BlockFrameMin: 'blockFrameMin',
  BlockFrameMax: 'blockFrameMax',
  HitFrameMin: 'hitFrameMin',
  HitFrameMax: 'hitFrameMax',
  NumHitsMin: 'numHitsMin',
  NumHitsMax: 'numHitsMax',
  HeatSmash: 'heatSmash',
  HeatEngager: 'heatEngager',
  Homing: 'homing',
  BalconyBreak: 'balconyBreak',
  Tornado: 'tonrnado',
  Jails: 'jails',
  NoJails: 'noJails',
  Chip: 'chip',
  HighCrush: 'cs',
  LowCrush: 'js',
  PowerCrush: 'pc',
  Spike: 'spk',
  FloorBreak: 'fbr',
  Knee: 'kne',
  Elbow: 'elb',
  Weapon: 'wpn',
  Stance: 'stance',
  Transition: 'transition',
  WallCrush: 'wc',
  Video: 'video',
  HitsGrounded: 'hitsGrounded',
  RemoveRecoveryHealth: 'removeRecoveryHealth',
  RecoverFullCrouch: 'recoverFullCrouch',
} as const

export const hitLevelValue = {
  Low: 'l',
  Mid: 'm',
  High: 'h',
  Throw: 't',
} as const
